<template>
    <div class="row">
        <div class="col-md-8 offset-lg-2">
            <div class="table-responsive">
                <DataTable class="table table-striped table-border display" :data="vacantes" :columns="columns"
                    :option="{ responsive: true, autoWith: false, dom: 'Bfrtip' }">
                    <thead>
                        <tr align="center">
                            <th class="col" scope="col">No. Vacante</th>
                            <th class="col" scope="col">Cía/División</th>
                            <th class="col" scope="col">Vacante</th>
                            <th class="col" scope="col">Área</th>
                            <th class="col" scope="col">Responsable</th>
                            <th class="col" scope="col">Ubicación</th>
                            <th class="col" scope="col">Fecha de Inicio</th>
                            <th class="col" scope="col">DT</th>
                            <th class="col" scope="col">Fecha Terna</th>
                            <th class="col" scope="col">DTerna</th>
                            <th class="col" scope="col">Status</th>
                            <th class="col" scope="col">KPI</th>
                            <th class="col" scope="col">Nivel</th>
                            <th class="col" scope="col">Candidatos Viables</th>
                            <th class="col" scope="col">Comentarios / Acciones</th>
                        </tr>
                    </thead>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<!-- <script>
import { ref } from "vue";
import { CompanyService } from "@/services";
import moment from 'moment-timezone';
moment.tz.setDefault("America/Mexico_City");

import DataTable from 'datatables.net-vue3';
import DataTableLib from 'datatables.net-bs5';
import Buttons from 'datatables.net-buttons-bs5';
import ButtonHtml5 from 'datatables.net-buttons/js/buttons.html5';
import print from 'datatables.net-buttons/js/buttons.print';
import pdfmake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import 'datatables.net-responsive-bs5';
import JsZip from 'jszip';
window.JsZip = JsZip;

DataTable.use(DataTableLib);
DataTable.use(pdfmake);
DataTable.use(ButtonHtml5);

export default {
    components: { DataTable },
    data() {
        return {
            vacantes: null,
        }
    }
}


</script> -->

